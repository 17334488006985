import React from "react";
import Layout from "../components/layout/Layout";

const Company = () => (
  <Layout>
    <section id="service" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto px-8 lg:flex flex-col">
        <div>
          <h2 className="text-2xl lg:text-5xl font-semibold">Company</h2>
          <h3 className="text-2xl lg:text-2xl mt-6 font-bold font-light">
            会社名
          </h3>
          <p className="text-sm lg:text-sm mt-0 text-gray-600">株式会社FUNEE</p>
          <h3 className="text-2xl lg:text-2xl mt-6 font-bold font-light">
            役員
          </h3>
          <p className="text-sm lg:text-sm mt-0 text-gray-600">
            代表取締役 楊 承峻（やん すんじゅん）
          </p>
          <h3 className="text-2xl lg:text-2xl mt-6 font-bold font-light">
            設立日
          </h3>
          <p className="text-sm lg:text-sm mt-0 text-gray-600">2019年3月18日</p>
          <h3 className="text-2xl lg:text-2xl mt-6 font-bold font-light">
            資本金
          </h3>
          <p className="text-sm lg:text-sm mt-0 text-gray-600">
            約2000万円(2022年2月1日現在)
          </p>
          <h3 className="text-2xl lg:text-2xl mt-6 font-bold font-light">
            事業内容
          </h3>
          <p className="text-sm lg:text-sm mt-0 text-gray-600">
            ファッションサービスの開発・運営
          </p>
          <h3 className="text-2xl lg:text-2xl mt-6 font-bold font-light">
            お問い合わせ
          </h3>
          <p className="text-sm lg:text-sm mt-0 text-gray-600">
            contact@funee.co.jp
          </p>
          <h3 className="text-2xl lg:text-2xl mt-6 font-bold font-light">
            所在地
          </h3>
          <p className="text-sm lg:text-sm mt-0 text-gray-600 mb-4">
            〒150-0046 東京都渋谷区松濤１丁目２６−４ 松涛パークハイツ 302号室
          </p>
        </div>
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1267.305038495547!2d139.69256851321964!3d35.65902692036437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188ae3678ffff9%3A0x813e94011ece59ec!2z5qCq5byP5Lya56S-RlVORUU!5e0!3m2!1sja!2sjp!4v1679032990216!5m2!1sja!2sjp"
          height="450"
          width="100%"
          loading="lazy"
        ></iframe>
      </div>
    </section>
  </Layout>
);

export default Company;
